import React, {useState, useEffect} from 'react';
import Iframe from 'react-iframe'
import "./assets/style.css"

function Admin() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])
  
  return (
    <div>
      {
        isLoading &&
        <div className='loader'>Chargement ...</div>
      }

      <Iframe url="https://airtable.com/embed/appxCNaNUe7I5c6SI/pagtqh2ikx5sQ6FDZ/form"
        position="absolute"
        width="100%"
        id="iframesehbi"
        className="el-iframe"
        height="100%"
      />
    </div>
  )
  }

export default Admin